import {useState, FC} from 'react';

import {useTransition, animated} from 'react-spring';
import {usePrevious, useSearchParam} from 'react-use';
import styled from 'styled-components';

import {UserRaceType, UserUnitPref, UserTempUnit} from '@stryd/models';

import {useApi} from 'src/contexts/ApiContext';
import {signUpEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';

import {StepFinished} from './StepFinished';
import {StepFive, StepFiveInputs} from './StepFive';
import {StepFour, StepFourInputs} from './StepFour';
import {StepOne, StepOneInputs} from './StepOne';
import {StepThree, StepThreeInputs} from './StepThree';
import {StepTwo, StepTwoInputs} from './StepTwo';

const AnimateStep = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 325px;
`;

const MAX_STEP = 5;

/**
 * 1. Each form field is required.
 */
export const SignUpSteps: FC = () => {
  const api = useApi();

  const search_username = useSearchParam('username') || '';
  const search_email = useSearchParam('email') || '';
  const search_id = useSearchParam('id') || '';
  const search_provider = useSearchParam('provider') || '';

  const [
    {
      username,
      email,
      password,
      firstname,
      lastname,
      timezone,
      distanceUnit,
      temperatureUnit,
      goalRace,
      gender,
      height,
      weight,
      day,
      month,
      year,
    },
    setForm,
  ] = useState({
    username: search_username,
    email: search_email,
    password: '',
    firstname: '',
    lastname: '',
    timezone: '',
    distanceUnit: '',
    temperatureUnit: '',
    goalRace: '',
    gender: '',
    height: '',
    weight: '',
    day: '1',
    month: '1',
    year: '1990',
  });

  const isCreatingFromSocial = Boolean(search_id && search_provider);

  const [step, setStep] = useState(0);
  const prevStep = usePrevious(step) || 0;

  const transitions = useTransition(step, {
    from: () => {
      return {opacity: 0, x: step >= prevStep ? '20%' : '-20%'};
    },
    enter: {opacity: 1, x: '0%'},
    leave: () => {
      return {opacity: 0, x: step > prevStep ? '-20%' : '20%'};
    },
  });

  const next = () => {
    if (step > MAX_STEP) return;
    setStep((step) => step + 1);
  };

  const handleSignUp = async (data: StepFiveInputs): Promise<void> => {
    const res = await api.auth.createUser({
      email,
      password,
      user_name: username,
      first_name: firstname,
      last_name: lastname,
      time_zone: timezone,
      race: goalRace as UserRaceType,
      gender: data.gender,
      height: +data.height,
      weight: +data.weight,
      birth: {
        day: +data.day,
        month: +data.month,
        year: +data.year,
      },
      units: distanceUnit as UserUnitPref,
      unit_env: temperatureUnit as UserTempUnit,
      ...(isCreatingFromSocial
        ? {
            has_social: true,
            provider: search_provider,
            provider_user_id: search_id,
          }
        : null),
    });

    if (res.ok) {
      track(signUpEvent({method: 'email'}));

      res.data.token && localStorage.setItem('token', res.data.token);
      res.data.refresh_token &&
        localStorage.setItem('refreshToken', res.data.refresh_token);
      res.data.client_id &&
        localStorage.setItem('refreshClientId', res.data.client_id);
      next();
    }
  };

  return (
    <div>
      {transitions((style, item) => {
        switch (item) {
          case 0:
            return (
              <AnimateStep
                style={{transform: `translate3d(-50%, -50%, 0)`, ...style}}
              >
                <StepOne
                  username={username}
                  email={email}
                  password={password}
                  isCreatingFromSocial={isCreatingFromSocial}
                  onSubmit={(data: StepOneInputs) => {
                    setForm((d) => ({...d, ...data}));
                    next();
                  }}
                />
              </AnimateStep>
            );
          case 1:
            return (
              <AnimateStep
                style={{transform: `translate3d(-50%, -50%, 0)`, ...style}}
              >
                <StepTwo
                  firstname={firstname}
                  lastname={lastname}
                  onSubmit={(data: StepTwoInputs) => {
                    setForm((d) => ({...d, ...data}));
                    next();
                  }}
                />
              </AnimateStep>
            );
          case 2:
            return (
              <AnimateStep
                style={{transform: `translate3d(-50%, -50%, 0)`, ...style}}
              >
                <StepThree
                  timezone={timezone}
                  onSubmit={(data: StepThreeInputs) => {
                    setForm((d) => ({...d, ...data}));
                    next();
                  }}
                />
              </AnimateStep>
            );
          case 3:
            return (
              <AnimateStep
                style={{transform: `translate3d(-50%, -50%, 0)`, ...style}}
              >
                <StepFour
                  distanceUnit={distanceUnit}
                  temperatureUnit={temperatureUnit}
                  goalRace={goalRace}
                  onSubmit={(data: StepFourInputs) => {
                    setForm((d) => ({...d, ...data}));
                    next();
                  }}
                />
              </AnimateStep>
            );
          case 4:
            return (
              <AnimateStep
                style={{transform: `translate3d(-50%, -50%, 0)`, ...style}}
              >
                <StepFive
                  gender={gender}
                  height={height}
                  weight={weight}
                  day={day}
                  month={month}
                  year={year}
                  distanceUnit={distanceUnit}
                  onSubmit={(data: StepFiveInputs) => {
                    setForm((d) => ({...d, ...data}));
                    handleSignUp(data);
                  }}
                />
              </AnimateStep>
            );
          case 5:
            return (
              <AnimateStep
                style={{transform: `translate3d(-50%, -50%, 0)`, ...style}}
              >
                <StepFinished />
              </AnimateStep>
            );
        }
      })}
    </div>
  );
};
